import { useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
// import { onMessageListener, requestForToken } from "../utils/firebase";
// import { toast } from "react-toastify";
// import { Spinner } from "react-bootstrap";
import "../pages/home-page/order/order-methods-modal/restaurants-list/RestaurantsList.css";
import PrePageLoader from "../components/loaders/prepage-loader/PrePageLoader";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../redux/slices/notificationsSlice";

const HomePage = lazy(() => import("../pages/home-page/HomePage"));
const OrderPage = lazy(() => import("../pages/order-page/OrderPage"));
const LoginPage = lazy(() => import("../pages/auth-page/login-page/LoginPage"));
const RegisterPage = lazy(() =>
  import("../pages/auth-page/register-page/RegisterPage")
);
const ForgetPassword = lazy(() =>
  import("../pages/auth-page/forget-password/ForgetPassword")
);
const RestaurantPage = lazy(() =>
  import("../pages/restaurant-page/RestaurantPage")
);

const OurBranches = lazy(() => import("../pages/our-branches/OurBranches"));
const ProfilePage = lazy(() => import("../pages/profile-page/ProfilePage"));
const NotificationsPage = lazy(() =>
  import("../pages/notifications-page/NotificationsPage")
);
const MyOrdersPage = lazy(() => import("../pages/myorders-page/MyOrdersPage"));
const CheckOtpCodePage = lazy(() =>
  import("../pages/auth-page/otp-process/CheckOtpCode")
);
const ResetPasswordByPhone = lazy(() =>
  import("../pages/auth-page/reset-password-by-phone/ResetPasswordByPhone")
);
const OrdersPage = lazy(() => import("../pages/myorders-page/OrdersPage"));
const SettingsPage = lazy(() => import("../pages/settings-page/SettingsPage"));
const TermsConditions = lazy(() =>
  import("../pages/settings-page/privacy-policy/TermsConditions")
);

// import HomePage from "../pages/home-page/HomePage";
// import OrderPage from "../pages/order-page/OrderPage";
// import LoginPage from "../pages/auth-page/login-page/LoginPage";
// import RegisterPage from "../pages/auth-page/register-page/RegisterPage";
// import RestaurantPage from "../pages/restaurant-page/RestaurantPage";
// import OurBranches from "../pages/our-branches/OurBranches";
// import UnderConstructionPage from "../pages/under-construction-page/UnderConstructionPage";
// import ProfilePage from "../pages/profile-page/ProfilePage";
// import NotificationsPage from "../pages/notifications-page/NotificationsPage";
// import MyOrdersPage from "../pages/myorders-page/MyOrdersPage";
// import SettingsPage from "../pages/settings-page/SettingsPage";

// import PaytrailFormPage from "../pages/paytrail-form-page/PaytrailFormPage";

function SiteRoutes() {
  // const [notification, setNotification] = useState({ title: "", body: "" });

  // useEffect(() => {
  //   onMessageListener()
  //     .then((payload) => {
  //       setNotification({
  //         title: payload?.notification?.title,
  //         body: payload?.notification?.body,
  //       });
  //     })
  //     .catch((err) => console.log("failed: ", err));
  //   if (notification?.title) {
  //     toast.success(
  //       <div>
  //         <h4>{notification.title}</h4>
  //         <p>{notification.body}</p>
  //       </div>
  //     );
  //   }
  //   // useDispatch(getData())
  // }, [notification]);

  const dispatch = useDispatch();
  const { notificationsStatus } = useSelector((state) => state.notifications);

  useEffect(() => {
    if (notificationsStatus === "idle") {
      dispatch(getNotifications());
    }
  }, [notificationsStatus, dispatch]);

  // useEffect(() => {
  //   if (notification?.title) {
  //     toast.success(<div>
  //       <h2>{notification.title}</h2>
  //       <p>{notification.body}</p>
  //     </div>);
  //   }
  // }, [notification]);

  // useEffect(() => {
  //   requestForToken();
  // }, []);

  // onMessageListener()
  //   .then((payload) => {
  //     setNotification({
  //       title: payload?.notification?.title,
  //       body: payload?.notification?.body,
  //     });
  //   })
  //   .catch((err) => console.log("failed: ", err));

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <HomePage />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default SiteRoutes;
