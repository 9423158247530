import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   /* inCheckout is a flag used to determine whether a functionality is dispatched from checkout page
   or not */
   inCheckout: false,
   /* stepsCompleted is a counter that represents in which checkout step the user is
   there are: 0 which means not in Checkout, 1 means in Checkout's first step, 2, and 3 */
   stepsCompleted: 0,
   /* editInfo is a state representing whether or not the user is editing their info
   in the Checkout section of the cart page */
   editInfo: false,
}

export const checkoutSlice = createSlice({
   name: 'checkout',
   initialState,
   reducers: {
        /* setInCheckout is used to specify whether the user is in Cart section from the Cart page or in Checkout section,
        it's needed for conditional rendering if the component.
        action.payload is a boolean, where true means in Checkout, false means in Cart */
        setInCheckout: (state, action) => {
            state.inCheckout = action.payload;
            if(!action.payload) {
                state.stepsCompleted = 0;
            }
        },

        /* setStepsCompleted controls  in which Checkout step the user is in,
        used to specify which components to render conditionally. 
        action.payload is a boolean, where true means step completed so we increment the steps, false mean back to Cart section. */
        setStepsCompleted: (state, action) => {
            if (action.payload) {
                state.stepsCompleted += 1;
            } else {
                state.stepsCompleted = 0;
            }
        },

        /* setEditInfo changes the state editInfo to true or false relying on the payload,
        which indicates whether or not the user is editing their info in Checkout section step 2. */
        setEditInfo: (state, action) => {
            state.editInfo = action.payload;
        }
   },
});

export const { setInCheckout, setStepsCompleted, setEditInfo } = checkoutSlice.actions;

export default checkoutSlice.reducer;