import { useDispatch, useSelector } from "react-redux";
import { displayNavSidebar } from "../../../redux/slices/showModalSlice";
import { useTranslation } from "react-i18next";

import "./InfoSection.css";
import LocationPin from "../../../assets/footer/location-pin.svg";
import Telephone from "../../../assets/footer/phone-call.svg";
import Email from "../../../assets/footer/email.svg";

import CustomList from "./custom-list/CustomList";
import { getFooter } from "../../../redux/slices/footerSlice";
import { useEffect } from "react";

function InfoSection({ inSidebar = false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { footerData, status } = useSelector((state) => state.footer);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getFooter());
    }
  }, [status, dispatch]);

  const hideNavSidebar = () => {
    dispatch(displayNavSidebar(false));
  };

  //   const branchesItems = [
  //     {
  //       iconSrc: LocationPin,
  //       href: "/restaurant",
  //       text: "Tuovilantie 54, 76850 Pieksämäki, Finland",
  //     },
  //   ];

  const branchesItems = footerData?.our_branches
    ? footerData?.our_branches?.map((el) => {
        return { iconSrc: LocationPin, href: "", text: el.location };
      })
    : [];

  const contactItems = [
    {
      iconSrc: Telephone,
      href: "",
      text: footerData?.contact_information?.map((el) => el?.email),
    },
    {
      iconSrc: Email,
      href: "",
      text: footerData?.contact_information?.map((el) => el?.phone_number),
    },
  ];

  const socialItems = footerData?.follow_us
    ? footerData?.follow_us?.map((el) => {
        return {
          iconSrc: `https://apis.pizzaservice.fi/images${el?.icon}`,
          href: el?.url,
        };
      })
    : [];
  // [
  //   { iconSrc: Facebook, href: "/about-us" },
  //   { iconSrc: Twitter, href: "/about-us" },
  //   { iconSrc: Instagram, href: "/about-us" },
  // ];

  return (
    <div
      className={`custom-info-div ${
        inSidebar ? "custom-info-div-sidebar" : ""
      }`}
    >
      {inSidebar ? null : (
        <CustomList title="Our Branches" items={branchesItems} />
      )}
      <CustomList
        title="Contact Information"
        items={contactItems}
        inSidebar={inSidebar}
      />
      <CustomList
        title="Follow Us"
        items={socialItems}
        inSidebar={inSidebar}
        inRow
      />
      {inSidebar ? (
        <a
          href="https://wsfinland.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="custom-span custom-anchor-tag custom-anchor-tag-sidebar"
          style={inSidebar ? { textAlign: "center" } : null}
          onClick={hideNavSidebar}
        >
          {t("Powered By")} WS-Finland
        </a>
      ) : null}
    </div>
  );
}

export default InfoSection;
