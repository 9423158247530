import { useDispatch } from "react-redux";
import { displayNavSidebar } from "../../../../redux/slices/showModalSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ListGroup from "react-bootstrap/ListGroup";

import "./CustomList.css";

import Icon from "../../../icon/Icon";

function CustomList({ title, items, inSidebar = false, inRow = false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hideNavSidebar = () => {
    if (inSidebar) {
      dispatch(displayNavSidebar(false));
    }
  };

  const RenderItems = () =>
    items.map(({ iconSrc, href, text }, index) => {
      return (
        <ListGroup.Item key={index} className="custom-list-item">
          {href === "" ? (
            <p
              className="custom-list-text d-flex align-items-center gap-1 mb-0"
              onClick={hideNavSidebar}
            >
              <img src={iconSrc} alt="icon" width={25} height={25} />

              <span>{t(text)}</span>
            </p>
          ) : (
            <Link
              to={href}
              target="_blank"
              className="custom-list-text d-flex align-items-center gap-1"
              onClick={hideNavSidebar}
            >
              <img src={iconSrc} alt="icon" width={25} height={25} />

              <span>{t(text)}</span>
            </Link>
          )}
        </ListGroup.Item>
      );
    });

  return (
    <ListGroup variant="flush" className="custom-list-group">
      <ListGroup.Item className="custom-list-item custom-list-title">
        {t(title)}
      </ListGroup.Item>
      {inRow ? (
        <ListGroup className="custom-list-group" horizontal>
          {" "}
          <RenderItems />{" "}
        </ListGroup>
      ) : (
        <RenderItems />
      )}
    </ListGroup>
  );
}

export default CustomList;
