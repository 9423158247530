import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  footerData: [],
  status: "idle",
  error: null,
};

// getFooter
export const getFooter = createAsyncThunk(
  "footer/getFooter",
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    try {
      const { data } = await axios.get(process.env.REACT_APP_API + "footer", {
        headers: {
          "X-localization": lang,
        },
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const footerSlice = createSlice({
  name: "footer",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFooter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFooter.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.footerData = action.payload;
      })
      .addCase(getFooter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default footerSlice.reducer;
