import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : "";

const initialState = {
  notificationsData: [],
  notificationsStatus: "idle",
  error: null,
};

// getNotifications
export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}notifications/getAll`,
        {
          headers: {
            "X-localization": lang,
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// MarkAsRead
export const MarkAsRead = createAsyncThunk(
  "notifications/MarkAsRead",
  async (payloadData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}notifications/markAsRead`,
        payloadData,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.notificationsStatus = "loading";
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notificationsStatus = "succeeded";
        state.notificationsData = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.notificationsStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default notificationsSlice.reducer;
