import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  myOrderData: {},
  myOrderStatus: "idle",
  selectedOrderNumber: null,
  error: null,
};

// showMyOrder
export const showMyOrder = createAsyncThunk(
  "myOrder/showMyOrder",
  async (params, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    const token = JSON.parse(localStorage.getItem("user")).token;
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API +
          `user/orderHistory/${params?.orderId}/show?relation[0]=restaurant&relation[1]=status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-localization": lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const myOrderSlice = createSlice({
  name: "myOrder",
  initialState,
  reducers: {
    handleSelectedOrderNumber: (state, action) => {
      state.selectedOrderNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(showMyOrder.pending, (state) => {
        state.myOrderStatus = "loading";
      })
      .addCase(showMyOrder.fulfilled, (state, action) => {
        state.myOrderStatus = "succeeded";
        state.myOrderData = action.payload[0];
      })
      .addCase(showMyOrder.rejected, (state, action) => {
        state.myOrderStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const { handleSelectedOrderNumber } = myOrderSlice.actions;
export default myOrderSlice.reducer;
