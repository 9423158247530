import axios from "axios";
import OneSignal from "react-onesignal";

export const runOneSignal = async () => {
  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  await OneSignal.init({
    appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
    // allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.login(user?.id ? user?.id : "");
  OneSignal.Slidedown.promptPush();
  sendToken(OneSignal.User.PushSubscription.id);
};

const sendToken = (token) => {
  if (token) {
    console.log("current token for client: ", token);
    localStorage.setItem("onesignal_Id", token);
    // Perform any other neccessary action with the token
    const user = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    let machineId = localStorage.getItem("MachineId");
    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem("MachineId", machineId);
    }
    axios
      .post(
        process.env.REACT_APP_API + "notifications/update_fcm",
        {
          fcm_token: token,
          device_id: machineId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      )
      .then((res) => {
        return res;
      });
  }
};
