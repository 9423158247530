import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { ToastContainer } from "react-toastify";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import NavBar from "./components/navbar/NavBar";
import SiteRoutes from "./routes/SiteRoutes";
import Footer from "./components/footer/Footer";

//
import { runOneSignal } from "./utils/onesignal";
import { useSelector } from "react-redux";

function App() {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";

  const { loggedIn } = useSelector((state) => state.signIn);

  // const runOneSignal = async () => {
  //   await OneSignal.init({
  //     appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
  //     allowLocalhostAsSecureOrigin: true,
  //   });
  //   OneSignal.login("10");
  //   OneSignal.Slidedown.promptPush();
  //   sendToken(OneSignal.User.PushSubscription.id)
  // };

  useEffect(() => {
    if (loggedIn) {
      runOneSignal();
    }
    // OneSignal.Notifications.
  }, [user, loggedIn]);

  useEffect(() => {
    // save lang in local storage
    localStorage.getItem("lang") === null &&
      localStorage.setItem("lang", JSON.stringify("fi"));

    document.documentElement.lang =
      localStorage.getItem("lang") !== null
        ? JSON.parse(localStorage.getItem("lang"))
        : "fi";
  }, []);

  return (
    <div className="App">
      <Router>
        <NavBar />
        <SiteRoutes />
        {/* {isMobile ? null : <Footer />} */}
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
        // style={{width: 'fit-content'}}
        style={{ minWidth: "fit-content" }}
      />
    </div>
  );
}

export default App;
