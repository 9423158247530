import './Logo.css';
import ServiceLogo from '../../assets/Logo.svg';


function Logo() {
    return (
        <img src={ServiceLogo} className="custom-logo" alt="logo" />
    );
}

export default Logo;