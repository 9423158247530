import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { displayNavSidebar } from "../../redux/slices/showModalSlice";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "./NavBar.css";

import Logo from "../logo/Logo";
import NavContainer from "../nav-container/NavContainer";
import BurgerButton from "../burger-button/BurgerButton";
import SideBar from "../sidebar/SideBar";

function NavBar() {
  const showNavSidebar = useSelector((state) => state.showModal.showNavSidebar);
  const isMobile = useMediaQuery({ query: "(max-width: 620px)" });
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(displayNavSidebar());
  };

  const hideNavSidebar = () => {
    dispatch(displayNavSidebar(false));
  };

  return (
    <Navbar fixed="top" className="custom-navbar">
      <Container>
        <NavLink className="navbar-brand" to="/">
          <Logo />
        </NavLink>
        <NavContainer className="justify-content-between" />
        {/* {isMobile ? (
          <BurgerButton rotated={showNavSidebar} onClick={onClickHandler} />
        ) : (
          <NavContainer className="justify-content-end" />
        )}
        <SideBar shown={showNavSidebar} setShown={hideNavSidebar} /> */}
      </Container>
    </Navbar>
  );
}

export default NavBar;
