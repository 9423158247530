// import { ReactSVG } from "react-svg";

import "./Icon.css";

function Icon({ src, className = "custom-icon" }) {
  // return <ReactSVG src={src} className={className} wrapper="span" />;
  return <img src={src} alt="" />;
}

export default Icon;
