import { Spinner } from "react-bootstrap";
import "./PrePageLoader.css";

const PrePageLoader = () => {
  return (
    <div className="prepage-loader">
      <Spinner
        animation="grow"
        variant="warning"
        className="custom-restaurants-list-spinner"
      />
    </div>
  );
};

export default PrePageLoader;
