import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  slidersData: [],
  slidersStatus: "idle",
  mostPopularData: [],
  mostPopularStatus: "idle",
  categoriesData: [],
  categoriesStatus: "idle",
  ourRestaurantsData: [],
  ourRestaurantsStatus: "idle",
  error: null,
};

// getSliders
export const getSliders = createAsyncThunk(
  "home/getSliders",
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API + "home/slider",
        {
          headers: {
            "X-localization": lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// getMostPopular
export const getMostPopular = createAsyncThunk(
  "home/getMostPopular",
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API + "home/mostPopular",
        {
          headers: {
            "X-localization": lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// getCategories
export const getCategories = createAsyncThunk(
  "home/getCategories",
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API + "home/categories",
        {
          headers: {
            "X-localization": lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// getOurRestaurants
export const getOurRestaurants = createAsyncThunk(
  "home/getOurRestaurants",
  async (_, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang;
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API + "home/ourRestaurants",
        {
          headers: {
            "X-localization": lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSliders.pending, (state) => {
        state.slidersStatus = "loading";
      })
      .addCase(getSliders.fulfilled, (state, action) => {
        state.slidersStatus = "succeeded";
        state.slidersData = action.payload;
      })
      .addCase(getSliders.rejected, (state, action) => {
        state.slidersStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(getMostPopular.pending, (state) => {
        state.mostPopularStatus = "loading";
      })
      .addCase(getMostPopular.fulfilled, (state, action) => {
        state.mostPopularStatus = "succeeded";
        state.mostPopularData = action.payload;
      })
      .addCase(getMostPopular.rejected, (state, action) => {
        state.mostPopularStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(getCategories.pending, (state) => {
        state.categoriesStatus = "loading";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categoriesStatus = "succeeded";
        state.categoriesData = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.categoriesStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(getOurRestaurants.pending, (state) => {
        state.ourRestaurantsStatus = "loading";
      })
      .addCase(getOurRestaurants.fulfilled, (state, action) => {
        state.ourRestaurantsStatus = "succeeded";
        state.ourRestaurantsData = action.payload;
      })
      .addCase(getOurRestaurants.rejected, (state, action) => {
        state.ourRestaurantsStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default homeSlice.reducer;
