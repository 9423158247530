import "./SideBar.css";

import NavContainer from "../nav-container/NavContainer";
import LegalInfoSection from "../footer/legal-info-section/LegalInfoSection";
import Footer from "../footer/Footer";

function SideBar({ shown = false, setShown, children }) {
  const siderStyle = children
    ? { transform: shown ? "translateX(0%)" : "translateX(-100%)" }
    : { transform: shown ? "translateX(0%)" : "translateX(100%)" };

  return (
    <div
      className={`custom-sidebar ${
        children ? "custom-sidebar-in-restaurant" : ""
      }`}
      style={siderStyle}
    >
      <div
        className={`custom-sidebar-content ${
          children ? "custom-sidebar-content-in-restaurant" : ""
        }`}
      >
        {children ? (
          children
        ) : (
          <>
            <NavContainer className="flex-column custom-sidebar-nav" />
            <LegalInfoSection inSidebar />
            <Footer inSidebar />
          </>
        )}
      </div>
      <div
        className={`custom-sidebar-hider ${
          children ? "custom-sidebar-hider-in-restaurant" : ""
        }`}
        onClick={setShown}
      ></div>
    </div>
  );
}

export default SideBar;
